import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const AboutPage = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav urlParam={url_param}/>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div>
      </div>
      <section className="section sectionFirst">
        <div className="container py-10">
          <div className="about-description">
            <p className="mb-5 lg:pr-5">
              BMW stands for the fascination of sheer driving pleasure – it
              offers the perfect combination of dynamism, sporty performance,
              groundbreaking innovations, and breathtaking design.
            </p>
            <p className="mb-5 lg:pr-5">
              The BMW brand has set itself the goal of continuing to be the
              driver of progress it always has been for over 100 years.
            </p>
            <p className="mb-5 lg:pr-5">
              Thinking ahead to tomorrow today has always been firmly rooted in
              the BMW ethos and serves as its driving force. This approach has
              helped the BMW Group become the world’s leading manufacturer of
              premium mobility solutions.
            </p>
            <p className="mb-5 lg:pr-5">
              Eurokars Auto Pte. Ltd. (EAPL) is a subsidiary of Eurokars Group,
              the largest privately owned car dealership in Singapore. As an
              official dealer of BMW, EAPL&nbsp;offers the full range of BMW
              vehicles – from fully electric BMW i models to high-performance
              BMW M models.
            </p>
            <p>
              With Eurokars Group’s longstanding excellence of customer
              centricity, EAPL is synonymous with unparalleled car ownership
              experiences. We take pride in offering exceptional personalised
              service, ensuring customers are provided with the highest
              standards in sales and aftersales support throughout their entire
              ownership journey.
            </p>
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-3">
              Visit Our BMW Showroom.
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-2 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Contact:
                </div>
                <div className="md:col-span-7 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">Eurokars Auto</span>
                  <br />
                  11 Leng Kee Road
                  <br />
                  Singapore 159091
                </div>
                <div className="md:col-span-2 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Contact:
                  </span>
                  6269&nbsp;8833
                </div>
                <div className="md:col-span-7 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday to Saturday: 8:30am – 7:00pm
                  <br />
                  Sunday and Public Holidays: 10:00am – 6:00pm
                </div>
              </div>
            </div>
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-3">
              Visit Our Authorised Service Centre.
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-2 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Contact:
                </div>
                <div className="md:col-span-7 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">Eurokars Auto</span>
                  <br />
                  29 Leng Kee Road
                  <br />
                  Singapore 159099
                  <br />
                </div>
                <div className="md:col-span-2 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Contact:
                  </span>
                  6219&nbsp;8388
                </div>
                <div className="md:col-span-7 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                      <span className="font-bold">BMW Service</span>
                      <br />
                      Monday to Friday: 8:00am – 6:00pm
                      <br />
                      Saturday: 8:00am – 12:00pm
                      <br />
                      Sunday and Public Holidays: Closed
                    </div>
                    <div>
                      <span className="font-bold">BMW Parts Counter</span>
                      <br />
                      Monday to Thursday: 8:30am – 6:00pm
                      <br />
                      Friday: 8:30am – 5:30pm
                      <br />
                      Saturday: 8:30am – 12:30pm
                      <br />
                      Sunday and Public Holidays: Closed
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "about" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage

const seoDetails = {
  title: "Eurokars Auto | About Us",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
